import React from 'react';
import { Card } from 'react-bootstrap';

import AppConstants from '../app/constants';
import CardTable from '../components/Table/CardTable';
import FranceMap from '../components/FranceMap';
import PageTitle from '../components/layout/PageTitle/PageTitle';
import SEO from '../components/seo';

import regionsReaCapacity from '../data/reg_rea_capa.json';

const RegionsPage = ({ franceStats }) => {

  const { regions } = franceStats;

  const regionsData = Object.values(regions).map((region) => ({
    ...region,
    rea_capacity: regionsReaCapacity[region.code].rea_capacity,
    percentRea: `${Math.round((region.totalRea / regionsReaCapacity[region.code].rea_capacity) * 100)}%`
  }));

  return (
    <>
      <SEO title="Evolution du Coronavirus en France par région" />

      <PageTitle title="Toutes les régions" />

      <Card>
        <Card.Body>
          <FranceMap type="regions" departements={franceStats.mapRegions} />
        </Card.Body>
      </Card>

      <CardTable
        headers={['', 'Total', 'Décès', 'Hosp', 'Réa', 'Rétablis', 'Capa Réa', '']}
        dataKeys={['nom', 'total', 'totalDc', 'totalHosp', 'totalRea', 'totalRad', 'rea_capacity', 'percentRea']}
        data={regionsData.sort((a, b) => a.nom.localeCompare(b.nom))}
        pathBase={AppConstants.ROUTE_PATHS.regions}
      />
    </>
  );
};

export default RegionsPage;
